import React, { useState } from "react"
import GitexPopupModal from "../../core/GitexPopupModal"

const NewGitex = () => {
  const [showFullVideo, setShowFullVideo] = useState(false)
  const [openGitex, setOpenGitex] = useState(false)
  const openGitexModal = () => {
    setOpenGitex(true)
  }
  const closeGitexModal = () => {
    setOpenGitex(false)
  }
  return (
    <>
      {openGitex && (
        <GitexPopupModal
          open={openGitex}
          closeGitexModal={closeGitexModal}
          url={"https://calendly.com/webmob"}
        />
      )}
      {showFullVideo ? (
        <div
          className="top-0 opacity-100 overflow-hidden w-screen h-screen fixed bg-shark-500 bg-opacity-80 left-0"
          style={{ transition: "opacity 0.5s ease-out", zIndex: 2000 }}
        >
          <div
            className="w-full h-full mx-auto mt-0"
            style={{
              padding: "0 1rem",
              transition: "all 0.5s ease-out",
              maxWidth: 940,
            }}
          >
            <div
              className="embed-container relative w-full max-w-full mt-0 top-1/2 transform -translate-y-1/2 h-0 bg"
              style={{ paddingBottom: "56.25%" }}
            >
              <div
                className="absolute left-1/2 transform -translate-x-1/2 "
                style={{ zIndex: 500, bottom: "-80px" }}
              >
                <div className={`mt-10 inline-flex flex-row  justify-center `}>
                  <a
                    href="/contact-us/"
                    target="_blank"
                    className="bg-blue-500 relative truncate  md:px-8 px-5 py-3 mr-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded"
                  >
                    Get a Free Quote
                  </a>
                  <a
                    href="/portfolio"
                    className="bg-blue-500 relative md:px-8 px-5 truncate  py-3   text-base leading-6 font-medium text-white hover:bg-blue-600 rounded"
                    target="_blank"
                  >
                    Explore Our Work
                  </a>
                </div>
              </div>
              <div
                id="video_close_btn"
                className="video_close_btn absolute right-0 opacity-100 cursor-pointer"
                aria-hidden="true"
                onClick={() => setShowFullVideo(false)}
                style={{
                  top: "-45px",
                  zIndex: 500,
                  fontFamily: "saileclight",
                  fontSize: "45px",
                  lineHeight: "25px",
                  color: "#ffffff",
                  transform: "rotate(45deg)",
                }}
              >
                +
              </div>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video
                id="home-page-video_full"
                preset="true"
                poster="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/10/1920x1080-with-logo-1-2.jpg"
                loop=""
                width="100%"
                height="100%"
                autoplay=""
                data-filename="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/05/All-In-One-Video-Compressed-New.mp4"
                className="svg-clipped-text zoom home_page_video top-0 left-0 w-full h-full absolute"
                controls="controls"
                controlslist="nodownload"
              >
                Your browser does not support HTML5 video.
                <source
                  src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/05/All-In-One-Video-Compressed-New.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      ) : null}
      <div
        className={`w-full bg-cover bg-center min-h-screen overflow-hidden flex justify-center`}
        style={{
          backgroundColor: "white",
        }}
      >
        <div className="w-full flex lg:flex-row flex-col justify-center items-center min-h-screen max-w-7xl py-8 md:py-16 lg:py-20 xl:py-20">
          <div className="lg:w-6/12 w-full flex justify-center items-center lg:px-3 px-4">
            <div className="flex justify-center flex-col ">
              <div className="h-full w-auto flex lg:justify-start justify-center lg:mt-0 mt-12">
                <img
                  // src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/06/Logo-london-tech-week.png"
                  src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/09/Gitex2024-Logo.webp"
                  alt="WebMobTech in Dubai"
                  title="WebMobTech in Dubai"
                  height="110px"
                  width="440px"
                  className="h-full w-auto"
                  loading="lazy"
                />
              </div>
              <div className="flex lg:justify-start justify-center">
                <h1
                  dangerouslySetInnerHTML={{
                    __html: `WebMobTech at GITEX 2024`,
                  }}
                  className="font-serif font-semibold lg:text-6xl text-3xl tracking-normal pt-10 text-shark-500 lg:text-left text-center"
                />
              </div>
              <div>
                <p class="mt-4 text-lg md:text-2xl lg:text-3xl text-center lg:text-left">
                  Elevate Your Business Through
                  <span class="font-bold text-blue-500">
                    {" "}
                    Artificial Intelligence{" "}
                  </span>
                  &{" "}
                  <span class="font-bold text-blue-500">
                    Advance Technologies!
                  </span>
                </p>
              </div>
              <div className="flex lg:justify-start justify-center">
                <p
                  dangerouslySetInnerHTML={{
                    __html: `14 October to 18 October 2024`,
                  }}
                  className="font-semibold lg:text-xl md:text-xl text-lg font-sans text-shark-400 mt-6 px-2 rounded lg:text-left text-center"
                />
              </div>

              {/* <div className="flex lg:justify-start justify-center">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: `Meet us at: Hall: D, Booth: #6 - 55735  Las Vegas, NV.`,
                                    }}
                                    className="font-semibold lg:text-xl md:text-xl text-lg font-sans text-white mt-4 px-2 bg-blue-600 rounded lg:text-left text-center"
                                />
                            </div> */}
              <div className="flex justify-center lg:justify-start w-full mt-3">
                <button
                  className="mt-4 md:my-8 lg:mt-6 bg-yellow-500 relative px-8 py-3 text-base leading-6 font-semibold text-shark-500  hover:bg-yellow-600 rounded"
                  onClick={openGitexModal} // remove after gitex2022
                >
                  Schedule Free Consultation Now
                </button>
              </div>
            </div>
          </div>
          <div className="lg:w-6/12 md:px-12 px-6 md:mt-24 lg:my-0  ">
            <div className="bg-white rounded-md">
              <div className="relative mx-auto rounded-md block">
                <div className="relative flex justify-center rounded-md">
                  <div className="relative flex items-center mx-auto ">
                    <img
                      // src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/08/Gitex1-Banner-Section.png"
                      // src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/05/Banner-Section.webp"
                      // src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/06/Banner-Section-london-tech-week.webp"
                      src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/09/Gitex2024_Banner_Section.webp"
                      alt="CES 2024"
                      title="CES 2024"
                      className="h-full"
                      height="100%"
                      width="100%"
                      loading="lazy"
                    ></img>
                    <div className="absolute w-full h-full flex justify-center items-center">
                      <div
                        className="absolute z-10 lg:w-20 lg:h-20 h-12 w-12 border-4 border-yellow-500 rounded-full bg-blue-500 cursor-pointer flex justify-center items-center "
                        aria-hidden="true"
                        onClick={() => setShowFullVideo(true)}
                      >
                        <div className="group relative h-full w-full flex justify-center items-center button-3 z-20 ">
                          <div
                            id="circle"
                            className="group-hover:opacity-100 flex absolute bg-white rounded-full hover:h-0 hover:w-0  w-0 h-0 opacity-0 transition-all duration-300 z-30"
                          ></div>
                          <img
                            src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/06/Yellow.png"
                            alt="logo png"
                            title=""
                            className="z-10 lg:w-5 w-2.5 group-hover:opacity-0 opacity-100 absolute transition-all duration-300"
                            height="16px"
                            width="16px"
                            loading="lazy"
                          />
                          <img
                            src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/06/Blue.png"
                            alt="logo png"
                            title=""
                            className="z-40 lg:w-5 w-2.5 group-hover:opacity-100 opacity-0 absolute transition-all duration-300"
                            height="16px"
                            width="16px"
                            loading="lazy"
                          />
                        </div>
                        <div className="video-text absolute whitespace-nowrap opacity-0 group-hover:opacity-100 left-0 bg-blue-500 text-xs rounded-md px-2 py-1 text-white transition-all duration-300 hidden lg:inline-block">
                          Watch Video
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <GitexPopupModal open={openGitex} closeGitexModal={closeGitexModal} /> */}
      </div>
    </>
  )
}

export default NewGitex
